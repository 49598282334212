@import '../../../../custom/fonts/font-texta';
@import '../../../../custom/fonts/font-druk';
@import '../../../../custom/fonts/font-teodor';
@import '../../../../custom/fonts/emarsys/index';

$font-family-druk-web: 'Druk Web', sans-serif;
$font-family-druk-wide-web: 'Druk Wide Web', $font-family-druk-web;
$font-family-texta: 'Texta', -apple-system, BlinkMacSystemFont,
'Segoe UI', 'Roboto', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-sans-serif: $font-family-texta;
$font-family-base: $font-family-sans-serif;
$font-family-custom1: $font-family-druk-web;
$font-family-custom2: $font-family-druk-wide-web;

// Emarsys
$font-family-cinzel: 'Cinzel', serif;
$font-family-conneqt: 'Conneqt', sans-serif;
$font-family-libre-baskerville: 'Libre Baskerville', serif;
$font-family-montserrat: 'Montserrat', sans-serif;
$font-family-playfair-display: 'Playfair Display', serif;
$font-family-poppins: 'Poppins', sans-serif;
$font-family-raleway: 'Raleway', sans-serif;
$font-family-roboto: 'Roboto Flex', sans-serif;
$font-family-saaSeriesDDOT: 'Saa Series DDOT', sans-serif;
$font-family-spartan: 'Spartan', sans-serif;
$font-family-teodor: 'Teodor', sans-serif;
$font-family-wulkan-display: 'Wulkan Display', serif;

.font-weight-bold{
    font-weight: bold !important;
}
