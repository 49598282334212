@font-face {
  font-family: "Druk Web";
  src: url("../../../assets/fonts/druk/Druk-Super-Web.woff2") format("woff2"),
  url("../../../assets/fonts/druk/Druk-Super-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "Druk Web";
  src: url("../../../assets/fonts/druk/Druk-Bold-Web.woff2") format("woff2"),
  url("../../../assets/fonts/druk/Druk-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "Druk Wide Web";
  src: url("../../../assets/fonts/druk/DrukWide-Super-Web.woff2") format("woff2"),
    url("../../../assets/fonts/druk/DrukWide-Super-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "Druk Wide Web";
  src: url("../../../assets/fonts/druk/DrukWide-Bold-Web.woff2") format("woff2"),
    url("../../../assets/fonts/druk/DrukWide-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}
