cx-page-layout {
  app-custom-box-link-image-item{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    border: solid thin white;
    min-height: 300px;
    @extend .align-items-center !optional;
    @extend .justify-content-center !optional;

    cx-media, > cx-media.is-missing{
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      display: flex;
      position: absolute;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .wrapper-text, .wrapper-link{
      display: flex;
      width: 100%;
      justify-content: center;
    }
    .wrapper-text{
      align-items: flex-end;
      height: 48px;
      font-weight: bold;
    }

    .wrapper-link{
      flex: auto;
      padding-top: 48px;
      align-items: center;
    }

    button,
    cx-generic-link {
      width: 80%;
    }

    button,
    cx-generic-link a {
      cursor: pointer;
      background-color: transparent;
      display: block;
      position: relative;
      color: white;
      font-family: $font-family-custom1;
      letter-spacing: 5.47px;
      font-size: 28px;
      font-weight: bold;
      line-height: 33px;
      text-align: center;
      text-transform: uppercase;
      padding: 15px;
      border: 2px solid white;
      margin: 0 auto;
      text-shadow: 0px 1px 2px rgba(0,0,0,.45);
      transition: backdrop-filter .1s ease-in;
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      line-height: 33px;

      h3 {
        font-size: 28px;
        font-family: inherit;
        font-weight: bold;
        line-height: 33px;
        margin: 0;
      }

      &:hover,
      &:focus,
      &:active {
        background-color: rgba(60, 60, 60, .1);
        backdrop-filter: blur(15px);
        -webkit-backdrop-filter: blur(15px);
        color: white;
        text-decoration: none;
      }
    }

    p{
      position: relative;
      font-size: 18px;
      color: white;
    }

    &.alternate {
      // ocultar título hasta hacer hover
      button,
      cx-generic-link a {
        opacity: 0;
        transition: opacity .15s ease-in;
      }
      &:hover,
      &:focus,
      &:active {
        button,
        cx-generic-link a {
          opacity: 1;
        }
      }
    }
  }
}

