@font-face {
    font-family: 'icomoon';
    src:  url('../../../assets/icons/icomoon.eot?nnt91g');
    src:  url('../../../assets/icons/icomoon.eot?nnt91g#iefix') format('embedded-opentype'),
      url('../../../assets/icons/icomoon.ttf?nnt91g') format('truetype'),
      url('../../../assets/icons/icomoon.woff?nnt91g') format('woff'),
      url('../../../assets/icons/icomoon.svg?nnt91g#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cart1:before {
  content: "\e9e2";
}
.icon-icon-type:before {
  content: "\e9dd";
  color: #a78d55;
}
.icon-icon-notes:before {
  content: "\e9de";
  color: #a78d55;
}
.icon-icon-format:before {
  content: "\e9df";
  color: #a78d55;
}
.icon-icon-alcohol:before {
  content: "\e9e0";
  color: #a78d55;
}
.icon-icon-aged:before {
  content: "\e9e1";
  color: #a78d55;
}
.icon-WineInModeration:before {
  content: "\e900";
}
.icon-ico-checkout-shipping:before {
  content: "\e901";
}
.icon-ico-checkout-payment:before {
  content: "\e902";
}
.icon-leaf:before {
  content: "\e903";
}
.icon-license:before {
  content: "\e904";
}
.icon-lighter:before {
  content: "\e908";
}
.icon-linearicons:before {
  content: "\e909";
}
.icon-line-spacing:before {
  content: "\e90a";
}
.icon-link:before {
  content: "\e90b";
}
.icon-list:before {
  content: "\e90c";
}
.icon-location:before {
  content: "\e90d";
}
.icon-lock:before {
  content: "\e90e";
}
.icon-magic-wand:before {
  content: "\e90f";
}
.icon-magnifier:before {
  content: "\e910";
}
.icon-map:before {
  content: "\e911";
}
.icon-map-marker:before {
  content: "\e912";
}
.icon-menu:before {
  content: "\e913";
}
.icon-menu-circle:before {
  content: "\e914";
}
.icon-mic:before {
  content: "\e915";
}
.icon-moon:before {
  content: "\e916";
}
.icon-move:before {
  content: "\e917";
}
.icon-music-note:before {
  content: "\e918";
}
.icon-mustache:before {
  content: "\e919";
}
.icon-neutral:before {
  content: "\e91a";
}
.icon-page-break:before {
  content: "\e91b";
}
.icon-paperclip:before {
  content: "\e91c";
}
.icon-paw:before {
  content: "\e91d";
}
.icon-pencil:before {
  content: "\e91e";
}
.icon-phone:before {
  content: "\e91f";
}
.icon-phone-handset:before {
  content: "\e920";
}
.icon-picture:before {
  content: "\e921";
}
.icon-pie-chart:before {
  content: "\e922";
}
.icon-pilcrow:before {
  content: "\e923";
}
.icon-plus-circle:before {
  content: "\e924";
}
.icon-pointer-down:before {
  content: "\e925";
}
.icon-pointer-left:before {
  content: "\e926";
}
.icon-pointer-right:before {
  content: "\e927";
}
.icon-pointer-up:before {
  content: "\e928";
}
.icon-poop:before {
  content: "\e929";
}
.icon-power-switch:before {
  content: "\e92a";
}
.icon-printer:before {
  content: "\e92b";
}
.icon-pushpin:before {
  content: "\e92c";
}
.icon-question-circle:before {
  content: "\e92d";
}
.icon-redo:before {
  content: "\e92e";
}
.icon-rocket:before {
  content: "\e92f";
}
.icon-sad:before {
  content: "\e930";
}
.icon-screen:before {
  content: "\e931";
}
.icon-select:before {
  content: "\e932";
}
.icon-shirt:before {
  content: "\e933";
}
.icon-smartphone:before {
  content: "\e934";
}
.icon-smile:before {
  content: "\e935";
}
.icon-sort-alpha-asc:before {
  content: "\e936";
}
.icon-sort-amount-asc:before {
  content: "\e937";
}
.icon-spell-check:before {
  content: "\e938";
}
.icon-star:before {
  content: "\e907";
}
.icon-star-empty:before {
  content: "\e93a";
}
.icon-star-half:before {
  content: "\e93b";
}
.icon-star-outline:before {
  content: "\e939";
}
.icon-store:before {
  content: "\e93c";
}
.icon-strikethrough:before {
  content: "\e93d";
}
.icon-sun:before {
  content: "\e93e";
}
.icon-sync:before {
  content: "\e93f";
}
.icon-tablet:before {
  content: "\e940";
}
.icon-tag:before {
  content: "\e941";
}
.icon-text-align-center:before {
  content: "\e942";
}
.icon-text-align-justify:before {
  content: "\e943";
}
.icon-text-align-left:before {
  content: "\e944";
}
.icon-text-align-right:before {
  content: "\e945";
}
.icon-text-format:before {
  content: "\e946";
}
.icon-text-format-remove:before {
  content: "\e947";
}
.icon-text-size:before {
  content: "\e948";
}
.icon-thumbs-down:before {
  content: "\e949";
}
.icon-thumbs-up:before {
  content: "\e94a";
}
.icon-train:before {
  content: "\e94b";
}
.icon-trash:before {
  content: "\e94c";
}
.icon-underline:before {
  content: "\e94d";
}
.icon-undo:before {
  content: "\e94e";
}
.icon-unlink:before {
  content: "\e94f";
}
.icon-upload:before {
  content: "\e950";
}
.icon-user:before {
  content: "\e951";
}
.icon-users:before {
  content: "\e952";
}
.icon-volume:before {
  content: "\e953";
}
.icon-volume-high:before {
  content: "\e954";
}
.icon-volume-low:before {
  content: "\e955";
}
.icon-volume-medium:before {
  content: "\e956";
}
.icon-warning:before {
  content: "\e957";
}
.icon-wheelchair:before {
  content: "\e958";
}
.icon-alarm:before {
  content: "\e959";
}
.icon-apartment:before {
  content: "\e95a";
}
.icon-arrow-down:before {
  content: "\e95b";
}
.icon-arrow-down-circle:before {
  content: "\e95c";
}
.icon-arrow-left:before {
  content: "\e95d";
}
.icon-arrow-left-circle:before {
  content: "\e95e";
}
.icon-arrow-right:before {
  content: "\e95f";
}
.icon-arrow-right-circle:before {
  content: "\e960";
}
.icon-arrow-up:before {
  content: "\e961";
}
.icon-arrow-up-circle:before {
  content: "\e962";
}
.icon-bicycle:before {
  content: "\e963";
}
.icon-bold:before {
  content: "\e964";
}
.icon-book:before {
  content: "\e965";
}
.icon-bookmark:before {
  content: "\e966";
}
.icon-briefcase:before {
  content: "\e967";
}
.icon-bubble:before {
  content: "\e968";
}
.icon-bug:before {
  content: "\e969";
}
.icon-bullhorn:before {
  content: "\e96a";
}
.icon-bus:before {
  content: "\e96b";
}
.icon-calendar-full:before {
  content: "\e96c";
}
.icon-camera:before {
  content: "\e96d";
}
.icon-camera-video:before {
  content: "\e96e";
}
.icon-car:before {
  content: "\e96f";
}
.icon-cart:before {
  content: "\e970";
}
.icon-chart-bars:before {
  content: "\e971";
}
.icon-checkmark-circle:before {
  content: "\e972";
}
.icon-chevron-down:before {
  content: "\e973";
}
.icon-chevron-down-circle:before {
  content: "\e974";
}
.icon-chevron-left:before {
  content: "\e975";
}
.icon-chevron-left-circle:before {
  content: "\e976";
}
.icon-chevron-right:before {
  content: "\e977";
}
.icon-chevron-right-circle:before {
  content: "\e978";
}
.icon-chevron-up:before {
  content: "\e979";
}
.icon-chevron-up-circle:before {
  content: "\e97a";
}
.icon-circle-minus:before {
  content: "\e97b";
}
.icon-clock:before {
  content: "\e97c";
}
.icon-cloud:before {
  content: "\e97d";
}
.icon-cloud-check:before {
  content: "\e97e";
}
.icon-cloud-download:before {
  content: "\e97f";
}
.icon-cloud-sync:before {
  content: "\e980";
}
.icon-cloud-upload:before {
  content: "\e981";
}
.icon-code:before {
  content: "\e982";
}
.icon-coffee-cup:before {
  content: "\e983";
}
.icon-cog:before {
  content: "\e984";
}
.icon-construction:before {
  content: "\e985";
}
.icon-crop:before {
  content: "\e986";
}
.icon-cross:before {
  content: "\e987";
}
.icon-cross-circle:before {
  content: "\e988";
}
.icon-database:before {
  content: "\e989";
}
.icon-diamond:before {
  content: "\e98a";
}
.icon-dice:before {
  content: "\e98b";
}
.icon-dinner:before {
  content: "\e98c";
}
.icon-direction-ltr:before {
  content: "\e98d";
}
.icon-direction-rtl:before {
  content: "\e98e";
}
.icon-download1:before {
  content: "\e98f";
}
.icon-drop:before {
  content: "\e990";
}
.icon-earth:before {
  content: "\e991";
}
.icon-enter:before {
  content: "\e992";
}
.icon-enter-down:before {
  content: "\e993";
}
.icon-envelope:before {
  content: "\e994";
}
.icon-exit:before {
  content: "\e995";
}
.icon-exit-up:before {
  content: "\e996";
}
.icon-eye:before {
  content: "\e997";
}
.icon-file-add:before {
  content: "\e998";
}
.icon-file-empty:before {
  content: "\e999";
}
.icon-film-play:before {
  content: "\e99a";
}
.icon-flag:before {
  content: "\e99b";
}
.icon-frame-contract:before {
  content: "\e99c";
}
.icon-frame-expand:before {
  content: "\e99d";
}
.icon-funnel:before {
  content: "\e99e";
}
.icon-gift:before {
  content: "\e99f";
}
.icon-graduation-hat:before {
  content: "\e9a0";
}
.icon-hand:before {
  content: "\e9a1";
}
.icon-heart:before {
  content: "\e9a2";
}
.icon-heart-pulse:before {
  content: "\e9a3";
}
.icon-highlight:before {
  content: "\e9a4";
}
.icon-history:before {
  content: "\e9a5";
}
.icon-home:before {
  content: "\e9a6";
}
.icon-hourglass:before {
  content: "\e9a7";
}
.icon-inbox:before {
  content: "\e9a8";
}
.icon-indent-decrease:before {
  content: "\e9a9";
}
.icon-indent-increase:before {
  content: "\e9aa";
}
.icon-italic:before {
  content: "\e9ab";
}
.icon-keyboard:before {
  content: "\e9ac";
}
.icon-laptop:before {
  content: "\e9ad";
}
.icon-laptop-phone:before {
  content: "\e9ae";
}
.icon-layers:before {
  content: "\e9af";
}
.icon-AmericanExpress:before {
  content: "\e9b0";
  color: #45090e;
}
.icon-arrow-left1:before {
  content: "\e9b1";
  color: #be7239;
}
.icon-arrow-right1:before {
  content: "\e9b2";
  color: #be7239;
}
.icon-checkbox:before {
  content: "\e9b3";
}
.icon-checkbox_checked:before {
  content: "\e9b4";
}
.icon-clock1:before {
  content: "\e9b5";
  color: #450c0f;
}
.icon-download:before {
  content: "\e9b6";
  color: #3d0706;
}
.icon-ico-cart:before {
  content: "\e9b7";
  color: #fff;
}
.icon-ico-cart-color:before {
  content: "\e9b8";
  color: #45090e;
}
.icon-ico-cart-item:before {
  content: "\e9b9";
  color: $color-extra-9;
}
.icon-ico-checkbox-checked:before {
  content: "\e9ba";
}
.icon-ico-checkbox-unchecked:before {
  content: "\e9bb";
}
.icon-ico-facebook:before {
  content: "\e9bc";
  color: $color-social-media;
}
.icon-ico-facebook-black:before {
  content: "\e9bd";
}
.icon-ico-instagram:before {
  content: "\e9be";
  color: $color-social-media;
}
.icon-ico-linkedin:before {
  content: "\e9bf";
  color: $color-social-media;
}
.icon-ico-mouse:before {
  content: "\e9c0";
}
.icon-icon-allergen:before {
  content: "\e9c1";
}
.icon-icon-grapes:before {
  content: "\e9c2";
  color: #a78d55;
}
.icon-icon-origin:before {
  content: "\e9c3";
}
.icon-icon-pdf:before {
  content: "\e9c4";
  color: #a78d55;
}
.icon-icon-pdf-link:before {
  content: "\e9c5";
  color: #45090e;
}
.icon-icon-pdf-link-hover:before {
  content: "\e9c6";
  color: #c56d4a;
}
.icon-icon-range:before {
  content: "\e9c7";
}
.icon-icon-season:before {
  content: "\e9c8";
  color: #a78d55;
}
.icon-icon-storage:before {
  content: "\e9c9";
}
.icon-icon-temperature:before {
  content: "\e9ca";
  color: #a78d55;
}
.icon-icon-weight:before {
  content: "\e9cb";
}
.icon-ico-pinterest:before {
  content: "\e9cc";
  color: $color-social-media;
}
.icon-ico-pinterest-black:before {
  content: "\e9cd";
}
.icon-ico-radio-checked:before {
  content: "\e905";
}
.icon-ico-radio-unchecked:before {
  content: "\e906";
}
.icon-ico-twitter:before {
  content: "\e9ce";
  color: $color-social-media;
}
.icon-ico-twitter-black:before {
  content: "\e9cf";
}
.icon-ico-user:before {
  content: "\e9d0";
  color: #fff;
}
.icon-ico-user-color:before {
  content: "\e9d1";
  color: #45090e;
}
.icon-ico-view-item:before {
  content: "\e9d2";
  color: $color-extra-9;
}
.icon-ico-youtube:before {
  content: "\e9d3";
  color: $color-social-media;
}
.icon-lock1:before {
  content: "\e9d4";
  color: #450c0f;
}
.icon-MasterCard:before {
  content: "\e9d5";
  color: #45090e;
}
.icon-Paypal .path1:before {
  content: "\e9d6";
  color: rgb(57, 57, 57);
}
.icon-Paypal .path2:before {
  content: "\e9d7";
  margin-left: -1.5517578125em;
  color: rgb(69, 12, 15);
}
.icon-Paypal .path3:before {
  content: "\e9d8";
  margin-left: -1.5517578125em;
  color: rgb(57, 57, 57);
}
.icon-radio:before {
  content: "\e907";
}
.icon-radio_checked:before {
  content: "\e9d9";
}
.icon-sprite-gallery:before {
  content: "\e9da";
}
.icon-ticket:before {
  content: "\e9db";
  color: #3d0706;
}
.icon-Visa:before {
  content: "\e9dc";
  color: #45090e;
}