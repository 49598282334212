app-custom-site-context-selector {
  @extend %cx-site-context-selector !optional;
  select {
    --cx-color-dark: --cx-color-transparent;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    background-color: transparent;
    color: inherit;
  }
  @include media-breakpoint-down(md) {
    select {
      color: white;
      background-color: black;
      i{
        color: white;
      }
    }
  }
  label{
    position: relative;
    i{
      position: absolute;
      right: -5px;
      top: 3px;
    }

    span {
      display: none;
    } 
  }
}
