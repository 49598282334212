footer{
  --cx-color-dark: var(--cx-color-background);
  --cx-color: var(--cx-color-primary);
  background-color: var(--cx-background-color, var(--cx-color-dark));
  color: var(--cx-color, var(--cx-color-inverse));

  cx-page-layout{
    &.footer{
      display: flex;
      flex-wrap: wrap;
      @extend .container !optional;

      cx-footer-navigation {
        @extend %cx-footer-navigation !optional;
        background-color: transparent;

        cx-navigation-ui {
          @extend .justify-content-start !optional;
          @extend .align-items-start !optional;

          @include media-breakpoint-between(md, lg) {
            > nav {
              margin-left: 10px;
              margin-right: 10px;
            }
          }

          > nav {
            margin: 3vw;
          }

          @include media-breakpoint-up(sm) {
            .ellipsis {
              display: block;
              line-height: 1em;
              padding-bottom: 10px;
            }

            h5 .ellipsis {
              white-space: nowrap;
            }
          }

          span {
            margin-bottom: 0;
          }

          .ellipsis {
            font-weight: var(--cx-font-weight);
          }

          h5 .ellipsis {
            font-weight: var(--cx-font-weight-bold);
          }
        }

        @include media-breakpoint-down(md) {
          a {
            min-height: 10px;
          }

          h5 {
            margin-bottom: 20px;
          }
        }

        a {
          color: var(--cx-color-secondary);
          font-size: 15px;

          &:hover {
            color: var(--cx-color-primary);
          }
        }

        nav {
          color: var(--cx-color-secondary);
        }
      }

      cx-page-slot{
        &.Footer{
          cx-navigation-ui{
            @extend .justify-content-center;
          }
        }
        &.FooterLeftSide{
          padding-bottom: 3vw;
          @include media-breakpoint-up(lg) {
            flex: 0 0 58.3333333333%;
            max-width: 58.3333333333%;
          }
          cx-navigation-ui {
            margin: .25vw .25vw .25vw 3vw;
            > nav {
              justify-content: flex-end;
              margin: 0;

              &:not(:first-child) {
                @include media-breakpoint-up(md) {
                  padding-left: 10px;
                }
              }

              a{
                padding-top: 5px;
                padding-bottom: 5px;
                padding-inline-end: 15px;
              }
              
              .ellipsis {
                text-transform: none;
                font-size: 15px;
              }
            }
          }
        }
        &.FooterRightSide{
          padding-bottom: 3vw;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-end;
          @include media-breakpoint-up(lg) {
            flex: 0 0 41.6666666667%;
            max-width: 41.6666666667%;
            padding: 0 3vw 0 0;
            align-items: flex-start;
            flex-wrap: nowrap;
          }

          cx-generic-link{
            display: flex;
            height: 100%;
            object-fit: contain;
          }

          app-custom-generic-link, .img-payment-brand{
            flex: unset;
            margin-right: 8px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        &.FooterBottom{
          display: block;
        }

        .img-payment-brand{
          width: auto;
          height: 25px;
        }
      }
    }
  }
}
