app-custom-newsletter {
  position: relative;
  display: block;

  form {
    padding: 40px 0px;
  }

  .title {
    text-align: center;
    margin-bottom: 36px;
    color: $compNewsletterTitleColor;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }

  .custom-newsletter-body {
    width: calc(50% + 140px);
    max-width: 724px;
    margin: 0 auto;

    .table-content {
      font-size: 11px;
    }
  }

  .form-check {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .custom-newsletter-content {
    margin-top: 30px;

    .table-content td{
      border: 1px solid $compNewsletterTableBorderColor;
    }
  }

  .form-control[type=email] {
    background-color: transparent;
    border-color: $compNewsletterBorderColor;
    height: 38px;
    margin-bottom: 4px;

    &:disabled {
      opacity: 0.65;
    }
  }

  .form-check-input {
    &:disabled {
      &,
      + .form-check-label {
        opacity: 0.65;
      }
    }
  }

  .newsletter-btn-action {
    min-height: 38px;
    padding: 0 12px;
    letter-spacing: 2.4px;
    font-size: 16px;
  }

  .form-check-label {
    font-size: 12px;
    text-transform: uppercase;
  }
  
  @include media-breakpoint-down(sm) {
    form { 
      max-width: 480px;
      margin-left: auto;
      margin-right: auto;
    }

    .title {
      padding: 0 20px;
    }
  }
}
