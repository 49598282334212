html[dir='rtl'] cx-breadcrumb nav span:not(:last-child):after {
  content: '|';
}

cx-breadcrumb {
  @extend %cx-breadcrumb !optional;
  @extend .container !optional;

  flex-direction: row;
  background-color: var(--cx-color-transparent);
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;

  > span {
    padding: 5px 10px 5px 5px;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1.6;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  nav {
    font-size: 16px;
    justify-content: left;

    span {
      color: var(--cx-color-text);
      display: flex;
      align-items: center;

        &:not(:last-child), & {
        &:after {
          content: '|';
        }
      }

      a {
        text-transform: none;
        color: var(--cx-color-text);
        padding: 5px 10px;
        min-height: 0;
      }

      &:first-child {
        a {
          padding-left: 5px;
        }
      }
    }
  }
}
