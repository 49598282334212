@font-face {
    font-family: 'Texta';
    src: url('../../../assets/fonts/texta/Texta-Regular.eot');
    src: local('Texta'), local('Texta-Regular'),
        url('../../../assets/fonts/texta/Texta-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/texta/Texta-Regular.woff2') format('woff2'),
        url('../../../assets/fonts/texta/Texta-Regular.woff') format('woff'),
        url('../../../assets/fonts/texta/Texta-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display:swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../../assets/fonts/texta/Texta-BoldIt.eot');
    src: local('Texta Bold It'), local('Texta-BoldIt'),
        url('../../../assets/fonts/texta/Texta-BoldIt.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/texta/Texta-BoldIt.woff2') format('woff2'),
        url('../../../assets/fonts/texta/Texta-BoldIt.woff') format('woff'),
        url('../../../assets/fonts/texta/Texta-BoldIt.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display:swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../../assets/fonts/texta/Texta-MediumIt.eot');
    src: local('Texta Medium It'), local('Texta-MediumIt'),
        url('../../../assets/fonts/texta/Texta-MediumIt.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/texta/Texta-MediumIt.woff2') format('woff2'),
        url('../../../assets/fonts/texta/Texta-MediumIt.woff') format('woff'),
        url('../../../assets/fonts/texta/Texta-MediumIt.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display:swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../../assets/fonts/texta/Texta-Light.eot');
    src: local('Texta Light'), local('Texta-Light'),
        url('../../../assets/fonts/texta/Texta-Light.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/texta/Texta-Light.woff2') format('woff2'),
        url('../../../assets/fonts/texta/Texta-Light.woff') format('woff'),
        url('../../../assets/fonts/texta/Texta-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display:swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../../assets/fonts/texta/Texta-Heavy.eot');
    src: local('Texta Heavy'), local('Texta-Heavy'),
        url('../../../assets/fonts/texta/Texta-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/texta/Texta-Heavy.woff2') format('woff2'),
        url('../../../assets/fonts/texta/Texta-Heavy.woff') format('woff'),
        url('../../../assets/fonts/texta/Texta-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display:swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../../assets/fonts/texta/Texta-ThintIt.eot');
    src: local('Texta Thin It'), local('Texta-ThintIt'),
        url('../../../assets/fonts/texta/Texta-ThintIt.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/texta/Texta-ThintIt.woff2') format('woff2'),
        url('../../../assets/fonts/texta/Texta-ThintIt.woff') format('woff'),
        url('../../../assets/fonts/texta/Texta-ThintIt.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display:swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../../assets/fonts/texta/Texta-It.eot');
    src: local('Texta It'), local('Texta-It'),
        url('../../../assets/fonts/texta/Texta-It.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/texta/Texta-It.woff2') format('woff2'),
        url('../../../assets/fonts/texta/Texta-It.woff') format('woff'),
        url('../../../assets/fonts/texta/Texta-It.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display:swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../../assets/fonts/texta/Texta-BlackIt.eot');
    src: local('Texta Black It'), local('Texta-BlackIt'),
        url('../../../assets/fonts/texta/Texta-BlackIt.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/texta/Texta-BlackIt.woff2') format('woff2'),
        url('../../../assets/fonts/texta/Texta-BlackIt.woff') format('woff'),
        url('../../../assets/fonts/texta/Texta-BlackIt.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display:swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../../assets/fonts/texta/Texta-Bold.eot');
    src: local('Texta Bold'), local('Texta-Bold'),
        url('../../../assets/fonts/texta/Texta-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/texta/Texta-Bold.woff2') format('woff2'),
        url('../../../assets/fonts/texta/Texta-Bold.woff') format('woff'),
        url('../../../assets/fonts/texta/Texta-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display:swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../../assets/fonts/texta/Texta-LightIt.eot');
    src: local('Texta Light It'), local('Texta-LightIt'),
        url('../../../assets/fonts/texta/Texta-LightIt.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/texta/Texta-LightIt.woff2') format('woff2'),
        url('../../../assets/fonts/texta/Texta-LightIt.woff') format('woff'),
        url('../../../assets/fonts/texta/Texta-LightIt.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display:swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../../assets/fonts/texta/Texta-HeavyIt.eot');
    src: local('Texta Heavy It'), local('Texta-HeavyIt'),
        url('../../../assets/fonts/texta/Texta-HeavyIt.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/texta/Texta-HeavyIt.woff2') format('woff2'),
        url('../../../assets/fonts/texta/Texta-HeavyIt.woff') format('woff'),
        url('../../../assets/fonts/texta/Texta-HeavyIt.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display:swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../../assets/fonts/texta/Texta-Thin.eot');
    src: local('Texta Thin'), local('Texta-Thin'),
        url('../../../assets/fonts/texta/Texta-Thin.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/texta/Texta-Thin.woff2') format('woff2'),
        url('../../../assets/fonts/texta/Texta-Thin.woff') format('woff'),
        url('../../../assets/fonts/texta/Texta-Thin.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display:swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../../assets/fonts/texta/Texta-Medium.eot');
    src: local('Texta Medium'), local('Texta-Medium'),
        url('../../../assets/fonts/texta/Texta-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/texta/Texta-Medium.woff2') format('woff2'),
        url('../../../assets/fonts/texta/Texta-Medium.woff') format('woff'),
        url('../../../assets/fonts/texta/Texta-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display:swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../../assets/fonts/texta/Texta-Black.eot');
    src: local('Texta Black'), local('Texta-Black'),
        url('../../../assets/fonts/texta/Texta-Black.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/texta/Texta-Black.woff2') format('woff2'),
        url('../../../assets/fonts/texta/Texta-Black.woff') format('woff'),
        url('../../../assets/fonts/texta/Texta-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display:swap;
}
