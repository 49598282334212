cx-storefront{
  &.LoginPageTemplate {
    @include media-breakpoint-down(md) {
      padding-bottom: 0;
      main{
        margin-top: -48px;
      }
    }
  }
}

.LoginPageTemplate {
  @extend %LoginPageTemplate !optional;
  padding-bottom: 3rem;

  app-base-store{
    display: none;
  }
  .BottomHeaderSlot{
    margin: 0;;
  }

  header {
    display: flex;
    &.is-expanded {
      .navigation {
        margin-top: 50px;
        height: calc(100vh - 50px);
        overflow: auto;
      }
    }
    .header {
      padding: 0 10px;
      margin-top: 25px;

      @include media-breakpoint-down(lg) {
        padding: 0 15px;
      }

      .HeaderText {
        left: 0;
        margin-left: 0;
        max-width: none;
        padding: 0;
        margin-top: -25px;
      }
    }

    .SiteLogo {
      padding: 0;
    }

    .navigation {
        margin-top: 25px;
    }
  }

  cx-reset-password.user-form {
    display: block;
    position: unset;

    button {
      @extend .btn-login;
      flex: inherit;
    }
  }

  cx-page-slot.BottomHeaderSlot:empty{
    display: none;
  }

  @include media-breakpoint-up(xl) {
    header:not(.is-expanded) cx-page-layout.navigation {
      padding-inline-end: 180px;
    }
  }
  @include media-breakpoint-up(lg) {
    header:not(.is-expanded) cx-page-layout.navigation {
      display: flex;
      justify-content: flex-end;
    }

    cx-page-slot {
      display: flex;
      align-items: center;
      flex-direction: column;
      max-width: var(--cx-page-width-max);
      padding-inline-end: 1.5rem;
      padding-bottom: 0;
      padding-inline-start: 1.5rem;
      margin: 0;

      > * {
        max-width: var(--cx-max-width, 50%);
        width: 100%;
      }
    }
    button {
      width: auto;
      min-width: 180px;
    }
    .navigation {
      .SiteContext {
        margin: 0;
        flex: 0;
        padding: 0;
        app-custom-site-context-selector {
          max-width: none;
        }
      }
      .SiteLinks {
        flex: 0;
        margin: 0;
        padding: 0 15px;
      }
    }
  }

  #loginFormSubmit {
    display: block;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  @include media-breakpoint-down(lg) {
    cx-page-slot {
      > * {
        max-width: var(--cx-max-width, 80%);
      }
    }
    cx-reset-password.user-form {
      --cx-max-width: 80%;
    }
  }

  .cx-section-title {
    margin: 40px 0 15px;
  }

  h1 {
    color: $primary;
    font-size: 80px;
    text-transform: uppercase;
  }

  h2 {
    color: white;
    font-size: 22px;
    letter-spacing: 0.3px;
    border-bottom: 1px solid white;
    padding-bottom: 10px;
    text-transform: uppercase;
    margin-bottom: 30px;
  }
  p {
    color: white;
    font-size: 17px;
  }
  .register {
    margin-top: 30px;
    a {
      color: white;
      font-size: 16px;
      text-shadow: 1px 1px 1px #000000;
      font-weight: 500;
      text-decoration: none;

      &:hover {
        color: lighten($primary, 10);
      }
    }

    > div { 
      display: inline-block;

      &:not(:last-child):after {
        color: white;
        content: "|";
        padding-left: 6px;
        padding-right: 6px;
      }
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: column-reverse !important;
    align-items: flex-start !important;

    .register-reset-password {
      margin: 0 !important;
      padding: 0 !important;
      margin-top: 16px !important;

     a {
        color: white !important;
        font-size: 16px;
        text-shadow: 1px 1px 1px #000000;
        font-weight: 500;
        text-decoration: none;
  
        &:hover {
          color: lighten($primary, 10) !important;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .PreHeader {
      padding: 0;
    }
    button[type="button"] {
      margin-bottom: 0;
    }
    .RightContentSlot {
      height: auto;
    }
    .LeftContentSlot {
      margin-top: 70px;
    }
    .RightContentSlot,
    .LeftContentSlot {
      padding: 0 30px;
    }
    cx-reset-password.user-form {
      --cx-max-width: 80%;
      button {
        flex: auto;
      }
    }
  }

  cx-page-layout {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    &.LoginPageTemplate{
      padding-top: 7%;
    }
    @include media-breakpoint-down(md) {
      &.LoginPageTemplate,
      &.navigation {
        display: block;
      }
      &.LoginPageTemplate{
        padding-top: 15%;
      }
    }
    @include media-breakpoint-down(sm) {
      &.LoginPageTemplate{
        padding-top: 0;
      }
    }
  }
  .wrapper-text {
    z-index: 1;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  label {
    span {
      text-transform: uppercase;
      color: white;
      opacity: 0.7;
      letter-spacing: 0.3px;
      font-size: 14px;
      text-shadow: 1px 1px 1px #000000;
    }
  }
  .SiteLinks {
    align-items: flex-start;
  }
  app-banner-image-text {
    text-shadow: 1px 1px 1px #000000;
    cx-media {
      position: static;

      img {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        object-fit: cover;
        object-position: top;
        height: 100%;
      }
    }

    @include media-breakpoint-down(md) {
      cx-media {
        overflow: hidden;
        img {
          width: 100%;
          max-width: none;
        }
      }
    }
  }

  footer {
    &,
    .footer {
      background-color: transparent;
    }

    .FooterBottom {
      padding: 0;
    }

    .cx-notice {
      position: relative;
      color:white;
      text-shadow: 1px 1px 1px #000000;
    }
    cx-paragraph {
      width: 100%;
      max-width: unset;
    }
  }

  .overlay {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
  }
}
