cx-page-layout {
    app-custom-box-link-image {
      .title {
        font-size: 32px;
        color: $secondary;
        text-align: center;
        letter-spacing: 1px;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-weight: bold;
        display: block;

        &:empty {
          display: none;
        }
      }

      .content {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-flow: row;
        position: relative;
        padding: 0;

        @include media-breakpoint-up(md) {
            grid-auto-columns: 1fr;
            grid-template-columns: 1fr;
            grid-auto-flow: column;
            height: 300px;
        }
      }
    }
}
