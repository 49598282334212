$space: 0.5rem;
header {
  @extend %header !optional;
  background: var(--cx-color-inverse);
  color: var(--cx-color-secondary);

  app-custom-mini-cart {
    a {
      position: relative;
      cursor: pointer;
      display: inline;

      .count {
        color: var(--cx-color-secondary);
        font-size: 10px;
        position: absolute;
        top: -1px;
        left: 5px;
        bottom: 0;
        right: 0;
        text-align: center;
      }
    }
  }

  app-base-store:not(:empty) {
    span {
      margin-right: 15px;
      background-color: $primary;
      color: white;
      cursor: pointer;
      border-radius: 15px;
      padding: 3px 10px;
    }

    @include media-breakpoint-down(md) {
      position: fixed;
      top: 50px;
    }
  }

  .header {
    position: relative;
    .HeaderText {
      text-align: center;
      background-color: $secondary;
      color: white;
      position: relative;
      top: 0;
      width: 100vw;
      left: 0;
      margin: -30px -15px 6px -16px;
      padding: 0 16px;

      @include media-breakpoint-up(lg) {
        width: calc(100vw);
        left: 50%;
        margin-left: -50vw;
        margin-top: 0;
        position: absolute;
      }

      p {
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      padding: 20px 10px 0 10px;
    }
    @include media-breakpoint-down(md) {
      padding: 30px 15px 0 15px;
    }

    > * {
      align-self: center;
      display: block;
      flex: none;
    }
  }

  @include media-breakpoint-down(md) {
    &.is-expanded {
      ::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: white;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: white;

        opacity: 0.7;
      }
      .SearchBox {
        visibility: hidden;
      }
      .SiteLinks {
        a {
          color: white;
        }
      }
      .SiteLogin {
        h5 {
          padding: 15px 0px 15px 0px;
        }
        i {
          color: white;
        }
      }
      .MiniCart {
        i {
          display: none;
          &:hover {
            opacity: 0.7;
          }
        }
      }
      cx-hamburger-menu {
        position: fixed;
        right: 20px;
        top: 12px;
        padding: 0;
      }
      .navigation {
        position: fixed;
        width: 85%;
        z-index: 3;
        left: 15%;
        max-height: calc(100vh - 75px);
        overflow: auto;
        top: 0;
        margin-top: 75px;
        padding-right: 16px;
        background-color: transparent;
      }
      .PreHeader {
        z-index: 4;
      }


      &:after {
        content: "";
        display: block;
        position: fixed;
        width: 85%;
        height: 100%;
        top: 0;
        left: 15%;
        background-color: black;
        z-index: 2;
        opacity: 0.9;
      }

    }
  }

  &:not(.is-expanded) {
    cx-page-layout.navigation {
      display: none;
    }
  }
  .SiteContext {
    app-custom-site-context-selector {
      &:last-child {
        display: none;
      }
    }
  }
  .SiteContext,
  .SiteLinks {
    font-size: 0.75rem;
    align-items: center;
    a {
      color: var(--cx-color-light);
      padding-right: 10px;
    }

    > * {
      flex: none;
    }
  }

  .SiteLinks {
    --cx-display: block;
    cx-link {
      display: block;
      padding-inline-end: 10px;
    }
  }

  .SiteLogo {
    flex: 15.01%;

    img {
      width: 190px;
      height: 100%;
    }
    @include media-breakpoint-down(sm) {
      img {
        width: 160px;
      }
    }
  }

  .searchbox {
    position: static;
    cursor: pointer;

    &--container {
      display: flex;
      justify-content: center;
      width: 100%;
      position: fixed;
      left: 0;
      top: 130px;
      background-color: transparent;
      border: none;
      z-index: 5;

      @include media-breakpoint-down(md) {
        top: 90px;
      }
      @include media-breakpoint-down(sm) {
        top: 64px;
      }
    }
    
    input {
      width: 100%;
      max-width: var(--cx-page-width-max);
      position: relative;
      opacity: 0;
      border: 0 solid $border-menu;
      transition: height 1s, opacity 0.9s;
      padding: 0 15px;
      height: 0;
      background-color: white;
      box-shadow: 0 5px 27px rgb(0 0 0 / 9%);

      @include media-breakpoint-down(md) {
        top: 30px;
      }
    }
  }

  .SearchBox {
    position: static;
    @include media-breakpoint-down(sm) {
      margin-top: 0;
      margin-inline-end: 0;
      margin-bottom: 0;
      margin-inline-start: auto;
    }
  }

  .NavigationBar {
    flex: 70%;

    @include media-breakpoint-down(md) {
      background-color: transparent;
      cx-category-navigation {
        width: 100%;
        height: auto;
        overflow-y: auto;
      }
    }
  }

  .cx-hamburger {
    display: block;
    padding: 5px 0 0 21px;
  }

  @include media-breakpoint-only(xs) {
    .SearchBox {
      margin-inline-start: auto;
    }
  }
  .MiniCart {
    a {
      text-decoration: none;
    }
    i {
      font-size: 22px;
      margin-left: 0;
      color: $secondary;
    }
  }
  // non-desktop
  @include media-breakpoint-down(md) {
    .SiteContext,
    .SiteLinks,
    .SiteLogin {
      background-color: transparent;
      color: $secondary;
      margin: 0 10px;
      text-transform: uppercase;
      font-weight: 300;
      font-size: 14px;
    }
    .SiteLogin {
      margin: 15px 15px 15px 30px;
    }
    .SiteLinks,
    .SiteLogin {
      i {
        font-size: 18px;
        color: $secondary;
      }
    }

    .SiteContext {
      padding: 5px 20px 5px 20px;
      i {
        color: white;
      }
    }

    .SiteLinks {
      padding: 35px 20px 0px 20px;
    }
  }

  // desktop
  @include media-breakpoint-up(lg) {
    header {
      padding: 0 $space * 2;
      display: grid;
      grid-template-columns: auto auto 50px 50px 50px;
      grid-template-rows: 35px 75px;
    }
    .cx-hamburger {
      display: none;
    }

    .MiniCart {
      margin-left: 5px;
      cx-icon {
        &::before {
          color: $secondary;
        }
      }
    }
    .SearchBox,
    .MiniCart,
    .SiteLogo,
    .SiteLogin {
      display: flex;
      i {
        color: $secondary;
      }
    }

    .SiteContext,
    .SiteLinks {
      display: flex;
      --cx-margin: #{$space} 8px;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1.5px;
    }

    .SiteContext {
      &:after {
        border-top: none;
      }
    }

    .SiteLinks {
      flex: 85%;
      margin-top: 14px;
      margin-bottom: 14px;
    }

    .SiteLogin {
      padding: $space $space * 2;
      justify-content: flex-end;
    }
  }
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 25px;
  height: 2px;
  background-color: #691c32;
  border-radius: 0;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.LandingPageTransparentHeaderTemplate {
  header {
  
    &:not(.sticky) ~ cx-global-message {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      transition: all .5s ease;

      .alert {
        .close {
          right: 1rem;
        }
      }

      @include media-breakpoint-down(md) {
        .alert {
          .close {
            right: 1rem;
          }
        }
      }
    }

    &.sticky {
      ~ cx-global-message {
        position: sticky;
        top: 0;
        margin-left: -8vw;
        margin-right: -8.1vw;
        transition: all .5s ease;

        @include media-breakpoint-down(md) {
          margin: 0;

          .alert {
            .close {
              right: 1rem;
            }
          }
        }
      }
    }
  }
}


@include media-breakpoint-down(md) {
  #cx-header {
    .header {
      .SiteLogin {
        display: none;
      }

      .MiniCart {
        display: flex;
      }
    }
  }
}

#cx-header {
  .header {
    .SiteLogo {
      cx-banner {
        cx-generic-link {
          a {
            min-height: 100%;
          }
        }
    }
  }
  }

}

a {
  min-height: 0;
}